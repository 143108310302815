@import "colours"

$primary: rgb(21, 136, 72)
$secondary: rgb(59, 74, 63)
$light-border-subtle: $charcoal-100
$light: $charcoal-25
$info: rgb(0, 194, 195)
$danger: rgb(218, 58, 62)
$grey: rgb(158, 11, 162)
$black: rgb(47, 47, 65)
$white_offset: rgb(246, 247, 255)

$highlight_colour: rgb(32, 186, 57) !important

$font-size-xs: 0.815rem
$font-size-small: 0.875rem
$font-size-md: 1rem
$font-size-large: 1.125rem
$font-size-display-small: 1.5rem

$line-height-xs: 1.125rem
$line-height-small: 1.25rem
$line-height-md: 1.5rem
$line-height-large: 1.75rem
$line-height-display-small: 2rem

$border-color: $charcoal-100
$border-radius-lg: 0.625rem
$border-radius-xl: 0.75rem
$border-radius-xxl: 1rem
$border-radius: $border-radius-xl

$card-border-color: $charcoal-50
$card-border-radius: $border-radius-xxl

$btn-border-width: 1px
$btn-border-radius-xs: 0.5rem
$btn-border-radius-sm: $border-radius
$btn-border-radius: $border-radius
$btn-border-radius-lg: $border-radius
$btn-line-height: 1.5rem
$btn-padding-x-xs: 8px - $btn-border-width
$btn-padding-y-xs: 5px - $btn-border-width
$btn-padding-x-sm: 12px - $btn-border-width
$btn-padding-y-sm: 8px - $btn-border-width
$btn-padding-x: 16px - $btn-border-width
$btn-padding-y: 8px - $btn-border-width
$btn-padding-x-lg: 24px - $btn-border-width
$btn-padding-y-lg: 14px - $btn-border-width
$btn-font-size-sm: 1rem
$btn-font-size: 1rem
$btn-font-size-lg: 1.125rem

$modal-header-padding: 1rem 1.5rem
$modal-content-border-radius: $border-radius-xxl
$modal-footer-bg: $charcoal-25
$modal-footer-border-width: 0
$modal-lg: 582px
$modal-dialog-margin: 1rem

$input-border-color: $charcoal-100
$input-border-radius-sm: 0.625rem
$input-border-radius: 0.625rem
$input-border-radius-lg: 0.625rem

$list-group-color: $charcoal-700

$table-th-font-weight: 600

$nav-pills-border-radius: 0
$nav-pills-link-active-bg: #FFFFFF
$nav-link-padding-x: 0.5rem
$nav-link-padding-y: 0.5rem

@import 'bootstrap/scss/functions'
@import 'bootstrap/scss/variables'
