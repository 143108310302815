@import 'variables'
@import 'bootstrap/scss/bootstrap'
@import 'bootstrap-icons/font/bootstrap-icons'
@import "prismjs/themes/prism.min.css"
@import "@fontsource/source-sans-pro"
@import "@fontsource/source-sans-pro/400.css"
@import "@fontsource/source-sans-pro/600.css"
@import "@fontsource/source-sans-pro/700.css"
@import 'buttons'

html
    background-color: $light

body
    font-family: "Source Sans Pro", sans-serif
